@font-face {
  font-family: "cookie";
  src: url("../../../font/OG-르네상스-비밀.woff") format("woff");
}

@font-face {
  font-family: "magical-sans";
  src: url("../../../font/Magical-Stylish-Sans-Serif-Demo.otf")
    format("opentype");
}

@font-face {
  font-family: "magical-script";
  src: url("../../../font/Magical-Stylish-Script-Demo.otf") format("opentype");
}

@font-face {
  font-family: "PlayfairDisplay-Black";
  src: url("../../../font/PlayfairDisplay-Black.otf") format("opentype");
  /* font-size: 8ch; */
}

@font-face {
  font-family: "IBMPlexMono-Text";
  src: url("../../../font/IBMPlexMono-Text.otf") format("opentype");
}

@font-face {
  font-family: "PlayfairDisplay-Medium_1";
  src: url("../../../font/PlayfairDisplay-Medium_1.otf") format("opentype");
}

@font-face {
  font-family: "PlayfairDisplay-Medium_1";
  src: url("../../../font/PlayfairDisplay-Medium_1.otf") format("opentype");
}
